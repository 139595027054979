/* eslint-disable react/function-component-definition */
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import { AppProvider } from './contexts/AppContext';
import ProductSelect from './pages/ProductSelect';
import BuildTheme from './pages/BuildTheme';
import Embed from './pages/Embed';
import './App.css';

const App = () => (
	<BrowserRouter>
		<AppProvider>
			<div className="App">
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/select" element={<ProductSelect />} />
					<Route path="/build" element={<BuildTheme />} />
					<Route path="/embed" element={<Embed />} />
				</Routes>
			</div>
		</AppProvider>
	</BrowserRouter>
);

export default App;
