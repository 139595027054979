import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SectionHeader from '../components/SectionHeaders';
import PoweredBy from '../components/PoweredBy';

import {
	Application,
	Card,
	Button,
	VisualPicker,
	VisualPickerOption,
} from '../components/vendors/ReactRainbow';
import { AppContext } from '../contexts/AppContext';
import PRODUCTS from '../models/products';
import Header from '../components/Header';

const theme = {
	rainbow: {
		palette: {
			brand: '#dc5648',
		},
	},
};

function ProductSelect() {
	const { updateSelectedProducts, selectedProducts } = useContext(AppContext);

	const handleOnChange = (value) => {
		updateSelectedProducts(value);
	};

	return (
		<Application
			style={{ fontFamily: 'Karla' }}
		>
			<Header />
			<div className="lg:mx-auto max-w-screen-2xl">

				<SectionHeader header="Product Selector" copy="Choose the products you want to make available." poweredBy />
				<Card className="flex flex-col flex-grow py-4 px-4 mx-10">
					<div className="justify-center py-8 sm:px-3 sm:mx-auto">
						<Application
							theme={theme}
							style={{ fontFamily: 'Karla' }}
						>
							<VisualPicker
								id="product-selector"
								multiple
								value={selectedProducts}
								onChange={handleOnChange}
								size="large"
							>
								{Object.keys(PRODUCTS).map((product) => (
									<VisualPickerOption
										name={PRODUCTS[product].id}
										className="px-5 text-center"
										key={PRODUCTS[product].id}
									>
										<FontAwesomeIcon icon={PRODUCTS[product].icon} className="fa-2xl" />
										<div className="py-2 text-xl font-bold">{PRODUCTS[product].name}</div>
										<p className="text-s px-2 h-4">{PRODUCTS[product].description}</p>

									</VisualPickerOption>
								))}
							</VisualPicker>
						</Application>

					</div>
					<Application
						theme={theme}
						style={{ fontFamily: 'Karla' }}
					>
						<Link to="/build">
							<Button
								label="Next"
								className="float-right"
								disabled={!selectedProducts.length}
								variant="brand"
							/>
						</Link>
					</Application>
				</Card>
				<div className="py-5 mx-10 mt-2 float-right">
					<PoweredBy partner="buddy" screen="" />
				</div>
			</div>

		</Application>

	);
}

export default ProductSelect;
