import React from 'react';
// import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
	faPowerOff,
	faPencilAlt,
	faBars,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import {
	MenuItem, MenuDivider, ButtonMenu, Application,
} from './vendors/ReactRainbow';

function Header() {
	return (
		<header className="header">
			<div className="flex w-full items-center">
				<div className="flex items-center">
					<img
						src="/buddy_lettermark_R.svg"
						alt="buddy"
						style={{ width: '3.2rem' }}
					/>
				</div>
				<div
					className="flex justify-end w-full items-center"
				>

					<ButtonMenu
						menuAlignment="right"
						menuSize="small"
						icon={<FontAwesomeIcon icon={faBars} color="#dc5648" />}
						className="mx-4"
					>
						<Application
							style={{ fontFamily: 'Karla' }}
						>
							<MenuItem
								label="Explore The Gateway"
								variant="header"
								style={{ fontFamily: 'Karla' }}
							/>

							<a href="https://buddy.insure" target="_blank" rel="noreferrer">
								<MenuItem label="About" />
							</a>
							<Link to="/select">
								<MenuItem label="Products" />
							</Link>
							<Link to={{}}>
								<MenuItem label="Support" />
							</Link>
							<MenuDivider variant="space" />
							<Link to={{}}>
								<MenuItem
									label="View Profile"
									icon={<FontAwesomeIcon icon={faUser} />}
									iconPosition="left"
								/>
							</Link>
							<Link to={{}}>
								<MenuItem
									label="Edit Profile"
									icon={<FontAwesomeIcon icon={faPencilAlt} />}
									iconPosition="left"
								/>
							</Link>

							<Link to="/">
								<MenuItem
									label="Logout"
									icon={<FontAwesomeIcon icon={faPowerOff} />}
									iconPosition="left"
								/>
							</Link>
						</Application>
					</ButtonMenu>
				</div>
			</div>
		</header>

	);
}

export default Header;
