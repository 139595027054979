/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react';
import THEMES from '../models/themes';

export const AppContext = createContext();

export function AppProvider({
	children,
}) {
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [theme, setTheme] = useState(THEMES.Light);
	const [data, setData] = useState({ policy: { meta: { producer: '', channel: '' } } });
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [themeModalIsOpen, setThemeModalIsOpen] = useState(false);

	const updateSelectedProducts = (newProduct) => setSelectedProducts(newProduct);

	const updateTheme = (newTheme) => {
		setTheme(THEMES[newTheme]);
	};

	const updateData = (newData) => {
		const newDataObj = {
			policy: {
				meta: {
					producer: newData.producer ? newData.producer : data.policy.meta.producer,
					channel: newData.channel ? newData.channel : data.policy.meta.channel,
				},
			},
		};
		setData(newDataObj);
	};

	const updateModalIsOpen = () => setModalIsOpen((current) => !current);

	const updateThemeModalIsOpen = () => setThemeModalIsOpen((current) => !current);

	return (

		<AppContext.Provider
			value={{
				selectedProducts,
				updateSelectedProducts,
				theme,
				updateTheme,
				data,
				updateData,
				modalIsOpen,
				updateModalIsOpen,
				themeModalIsOpen,
				updateThemeModalIsOpen,
			}}
		>
			{children}
		</AppContext.Provider>
	);
}
