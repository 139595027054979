/* eslint-disable no-console */
import React, {
	useContext, useState, useEffect,
} from 'react';
import BuddyOfferElement from '@buddy-technology/offer-component';
import {
	Card, RadioButtonGroup, Application, Button,
} from 'react-rainbow-components';

import { useNavigate } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';
import PRODUCTS from '../models/products';

const buddyRedTheme = {
	rainbow: {
		palette: {
			brand: '#dc5648',
		},
	},
};

function OfferElementCard({
	embed = false,
}) {
	const [passedData, setPassedData] = useState(null);

	const handler = (message) => {
		if (message?.data?.payload?.selectedProducts) {
			setPassedData(message?.data?.payload);
		}
	};

	window.addEventListener('message', handler);

	const navigate = useNavigate();

	const {
		theme,
		data,
	} = useContext(AppContext);

	let products;

	// try to get the products from context
	const {
		selectedProducts = [],
	} = useContext(AppContext);

	// cant mutate stateful data
	products = [...selectedProducts];

	// if there are no products from context, lets get them from postMessage
	if (!products.length) {
		products = passedData?.selectedProducts;
	}

	const [selectedProduct, setSelectedProduct] = useState();

	useEffect(() => {
		// if there are still no products, navigate back to login
		if (!products?.length && !embed) {
			return navigate('/');
		}
		// if there are products the and not an embed then set the default product
		if (!embed) {
			setSelectedProduct(products[0]);
		}

		return undefined;
	}, []);

	const handleProductSelection = (value) => {
		const { target: { defaultValue } } = value;
		setSelectedProduct(defaultValue);
	};

	const radioOptions = products?.map((product) => (
		{
			value: PRODUCTS[product].id,
			label: PRODUCTS[product].name,
		}
	));

	const offerElementProps = {
		ion: selectedProduct,
		partnerID: 'p-buddytest',
		stage: 'STAGING',
		// eslint-disable-next-line no-nested-ternary
		theme: (embed ? passedData ? passedData?.theme : null : theme.theme),
		// eslint-disable-next-line no-nested-ternary
		data: (embed ? passedData ? passedData?.data : null : data),
		overflow: 'hidden',
	};

	const handleOnChange = (value) => {
		setSelectedProduct(value);
	};

	return (

		<Card className="py-2 px-2 my-2 mx-2">
			<div className="px-1 my-2 mx-2 flex flex-col">
				{!embed ? (
					<Application theme={buddyRedTheme}>
						<RadioButtonGroup
							id="radio-button-group-component-1"
							options={radioOptions}
							value={selectedProduct}
							onChange={handleProductSelection}
							variant="brand"
							className="truncate"
						/>
					</Application>
				) : (
					<Application
						theme={buddyRedTheme}
						style={{
							fontFamily: 'Karla',
							width: '1000px',
							paddingLeft: '48px',
							paddingRight: '48px',
							margin: '0 auto',
							display: 'flex',
							justifyContent: 'start',
						}}
					>
						<div className="flex justify-start align-center w-full">
							{products?.map((product) => (
								<Card onClick={() => handleOnChange(PRODUCTS[product].id)} className="mr-4 last:mr-o flex flex-col items-between justify-between px-3 py-5 w-1/3 ">
									<div className="flex mb-2 justify-center items-start">
										<div className="w-1/5 flex items-start">
											<img src={PRODUCTS[product].image} alt="tag" width="60" height="60" />
										</div>
										<div className="flex flex-col w-4/5">
											<div className="ml-2 text-xl font-bold">{PRODUCTS[product].name}</div>
											<p className="px-2 text-left text-sm grow">{PRODUCTS[product].embedDescription}</p>
										</div>
									</div>
									<div className="flex items-end justify-end w-full">
										<Button
											label="Get Covered"
											// variant="neutral"
											size="small"
											className="px-0 mt-1 mx-2 text-black bg-buddy-red"
											onClick={() => handleOnChange(PRODUCTS[product].id)}
										/>
									</div>
								</Card>
							))}

						</div>
					</Application>
				)}

				<div className="my-5 w-full">
					{selectedProduct
						? <BuddyOfferElement {...offerElementProps} />
						: null}

				</div>
			</div>
		</Card>

	);
}

export default OfferElementCard;
