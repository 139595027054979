import React from 'react';
import {
	Application,
} from 'react-rainbow-components';

function SectionHeader({ header, copy }) {
	return (
		<Application
			style={{ fontFamily: 'Karla' }}
		>
			<div className="py-5 px-3 mx-10 mt-2 flex justify-between items-between">
				<div className="flex flex-col">
					<div className=" text-3xl font-bold text-buddy-red">
						{header}
					</div>
					<div className=" text-lg font-bold">
						{copy}
					</div>
				</div>
			</div>
		</Application>

	);
}

export default SectionHeader;
