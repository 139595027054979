/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
	Card, Input, Button, Application, Spinner,
} from 'react-rainbow-components';
import { useNavigate } from 'react-router-dom';
import BuddyB from '../components/assets/BuddyB';

function Login() {
	const buddyRedTheme = {
		rainbow: {
			palette: {
				brand: '#dc5648',
			},
		},
	};

	const navigate = useNavigate();

	// const [email, setEmail] = useState('');

	// const [password, setPassword] = useState('');

	const [isLoggingIn, setIsLoggingIn] = useState(false);

	// const handleFormUpdate = (_data) => {
	// 	const { target: { name, value } } = _data;
	// 	// eslint-disable-next-line no-unused-expressions
	// 	name === 'email' ? setEmail(value) : setPassword(value);
	// };

	useEffect(() => {
		if (isLoggingIn) {
			setTimeout(() => {
				navigate('/select');
			}, 1000);
		}
	});

	// const isFilled = email && password;

	return (
		<div className="h-screen w-full flex justify-center items-center">
			<Application
				theme={buddyRedTheme}
				style={{ fontFamily: 'Karla' }}
			>
				{!isLoggingIn ? (
					<Card
						className="p-7 w-full"
					>
						<div className="flex flex-col items-center">
							<BuddyB
								className="mt-5"
							/>
							<Input
								label="Email"
								name="email"
								labelAlignment="left"
								className="mb-2 mt-5"
								// onChange={handleFormUpdate}
							/>
							<Input
								labelAlignment="left"
								name="password"
								label="Password"
								type="password"
								className="mb-6"
								// onChange={handleFormUpdate}
							/>
							<a href="" className="mb-6 text-blue"> Forgot Password</a>
							<Button
								variant="brand"
								label="Log In"
								// disabled={!isFilled}
								onClick={() => setIsLoggingIn(true)}
							/>
						</div>
					</Card>

				) : <Spinner size="x-large" variant="brand" />}
			</Application>
		</div>
	);
}

export default Login;
