import React from 'react';
import OfferElementCard from '../components/OfferElementCard';

function Embed() {
	return (
		<div>
			<OfferElementCard
				embed
			/>
		</div>
	);
}

export default Embed;
