import React from 'react';

const buddySite = 'https://buddy.insure';

function PoweredBy({
	url = buddySite, partner, screen, className,
}) {
	return (
		<a href={`${url}/?utm_source=${partner}&utm_medium=embed_widget&utm_campaign=${screen}`} className={className} target="_blank" rel="noreferrer">
			<img src="https://buddy-img.s3.amazonaws.com/powered-by/powered_by_red.svg" alt="Powered by Buddy" />
		</a>
	);
}

export default PoweredBy;
