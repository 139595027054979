import {
	faPaw, faSyringe, faWifi,
} from '@fortawesome/free-solid-svg-icons';
import dog from '../images/dog.png';
import boat from '../images/boat.png';
import tulip from '../images/tulip.png';

const PRODUCTS = {
	BOOST_PET_DEV: {
		name: 'Pet',
		id: 'BOOST_PET_DEV',
		embedDescription: 'Pet Health Insurance for your dog or cat.',
		description: 'Pet Health Insurance for your dog or cat.',
		image: dog,
		icon: faPaw,
	},
	VIGILANCE_OCCUPATIONAL_ACCIDENT: {
		name: 'Needle Stick',
		id: 'VIGILANCE_OCCUPATIONAL_ACCIDENT',
		embedDescription: 'Occupational Accident Insurance built for healthcare workers.',
		description: 'Occupational Accident Insurance built for healthcare workers.',
		image: boat,
		icon: faSyringe,
	},
	BLINK_CYBER: {
		name: 'Cyber',
		id: 'BLINK_CYBER',
		embedDescription: 'Cyber insurance for your digital life.',
		description: 'Cyber insurance for your digital life.',

		image: tulip,
		icon: faWifi,
	},
};

export default PRODUCTS;
