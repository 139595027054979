/* eslint-disable import/prefer-default-export */
import chubb from '../images/chubb.png';
import combined from '../images/combined.png';
import blink from '../images/blink.png';
// import vigilance from '../images/vigilance.png';

const THEMES = {
	Light: { // Corporate Light
		name: 'Light',
		id: 'LIGHT',
		theme: {
			baseTheme: 'base',
			palette: 'base',
			overrides: {
				styles: {
					'#boost-pet-dev #offer-content-container-BEFORE-0': {
						display: 'flex',
						alignItems: 'center',
					},
					'#boost-pet-dev #offer-content-container-BEFORE-0 p': {
						marginRight: '3rem',
						maxWidth: '50%',
					},
					'#offer-view-container hr': {
						marginTop: '1em',
						marginBottom: '1em',
					},
					'.progress-bar-label': {
						display: 'none',
					},
					'.progress-bar-container': {
						marginBottom: '2.2rem',
					},
					'#offer-container': {
						maxWidth: '1000px',
						margin: '0 auto',
						textAlign: 'left',
					},
					'.input-text, .input-select': {
						border: 'solid 1px',
						borderRadius: '2px',
						borderColor: '#88898a',
						fontSize: '15px',
					},
					'.input-label': {
						textAlign: 'left',
						fontSize: '14px',
					},
					'.input-error': {
						textAlign: 'left',
						fontSize: '12px',
					},
					'input[type=radio], input[type=checkbox]': {
						borderRadius: '5px',
					},
					'.radio-button-label, .checkbox-wrapper label': {
						fontWeight: 'bold',
					},
					body: {
						fontFamily: 'Lato, sans-serif',
						fontSize: '16px',
						color: '#696969',
						'@media (min-width: 768px)': {
							fontSize: '16px',
						},
						margin: '0 auto',
					},
					h1: {
						fontWeight: '600',
						fontSize: '35px',
						color: 'var(--color-primary)',
					},
					h2: {
						fontWeight: '400',
						fontSize: '35px',
						color: 'var(--color-primary)',
					},
					h3: {
						fontWeight: '600',
						fontSize: '20px',
						fontFamily: 'Rubik, sans-serif',
						color: 'var(--color-primary)',
					},
					a: {
						color: 'var(--color-primary)',
					},
					'ul, ol': {
						listStylePosition: 'inside',
					},
					table: {
						backgroundColor: '#fff',
					},
					th: {
						backgroundColor: 'var(--color-primary)',
						color: '#FFF',
					},
					'.button-secondary': {
						textTransform: 'uppercase',
						padding: 0,
						color: 'var(--color-primary)',
						fontWeight: 'bold',
						fontSize: '14px',
						height: '40px',
						background: 'white',
						'&:hover': {
							backgroundColor: 'var(--color-primary)',
							color: '#FFF',
							textDecoration: 'none',
						},
					},
					'.button-primary': {
						textTransform: 'uppercase',
						padding: 0,
						color: '#fff',
						fontWeight: 'bold',
						fontSize: '14px',
						height: '40px',
						background: 'var(--color-primary)',
						'&:hover': {
							backgroundColor: 'var(--color-secondary)',
							color: '#fff',
						},
					},
					button: {
						transition: 'background-color .4s ease 0s',
						minWidth: '150px!important',
						borderRadius: '4px!important',
						border: '2px solid var(--color-primary)',
					},
				},
				colors: {
					primary: '#2862a4',
					secondary: '#31b0d5',
					textPrimary: '#696969',
				},
			},
		},
		image: chubb,
	},
	Dark: { // Corporate Dark Pill
		name: 'Dark',
		id: 'DARK',
		theme: {
			baseTheme: 'base',
			palette: 'base',
			overrides: {
				styles: {
					'#boost-pet-dev #offer-content-container-BEFORE-0': {
						display: 'flex',
						alignItems: 'center',
					},
					'#boost-pet-dev #offer-content-container-BEFORE-0 p': {
						marginRight: '3rem',
						maxWidth: '50%',
					},
					'#offer-view-container hr': {
						marginTop: '1em',
						marginBottom: '1em',
					},
					'#offer-container': {
						maxWidth: '1000px',
						margin: '0 auto',
						textAlign: 'left',
						background: '#FFF',
					},
					'.progress-bar-label': {
						display: 'none',
					},
					'.view-title': {
						marginTop: '3rem',
					},
					'.input-text, .input-select': {
						color: '#666',
						border: 'solid 1px',
						borderRadius: '22px',
						borderColor: '#d0d0d6',
						fontSize: '13px',
						height: 'auto',
					},
					'.input-label': {
						fontSize: '14px',
						fontFamily: 'Rubik, sans-serif',
						color: '#40352C',
					},
					'.input-error': {
						textAlign: 'left',
						fontSize: '12px',
					},
					'.input-invalid': {
						color: 'red',
					},
					'.radio-button-wrapper': {
						display: 'flex',
					},
					'input[type=radio], input[type=checkbox]': {
						borderRadius: '5px',
					},
					'.radio-button-label, .checkbox-wrapper label': {
						fontWeight: 'bold',
					},
					'label[for="policy-plan"], label[for="policy-planPayment"]': {
						border: 'none!important',
					},
					'#policy-planPayment-radio-button-wrapper': {
						marginBottom: '1rem',
					},
					'.single-form-view-container': {
						borderBottomColor: '#E18477',
					},
					body: {
						fontFamily: 'Rubik, sans-serif',
						fontSize: '16px',
						color: '#40352C',
						'@media (min-width: 768px)': {
							fontSize: '16px',
						},
						margin: '0 auto',
					},
					h1: {
						fontWeight: '600',
						fontSize: '35px',
						color: 'var(--color-primary)',
					},
					h2: {
						fontWeight: '400',
						fontSize: '35px',
						color: 'var(--color-primary)',
					},
					h3: {
						fontWeight: '600',
						fontSize: '20px',
						fontFamily: 'Rubik, sans-serif',
						color: 'var(--color-primary)',
					},
					'ul, ol': {
						listStylePosition: 'inside',
					},
					table: {
						backgroundColor: '#fff',
					},
					th: {
						backgroundColor: 'var(--color-primary)',
						color: '#FFF',
					},
					a: {
						color: 'var(--color-secondary)',
					},
					'.button-secondary': {
						textTransform: 'uppercase',
						padding: 0,
						color: '#FFF',
						fontWeight: 'bold',
						fontSize: '14px',
						height: '40px',
						background: 'var(--color-secondary)',
						'&:hover': {
							backgroundColor: 'var(--color-primary)',
							color: '#fff',
							textDecoration: 'none',
						},
					},
					'.button-primary': {
						textTransform: 'uppercase',
						padding: 0,
						color: '#fff',
						fontWeight: 'bold',
						fontSize: '14px',
						height: '40px',
						background: 'var(--color-primary)',
						'&:hover': {
							backgroundColor: 'var(--color-secondary)',
							color: '#fff',
						},
					},
					button: {
						transition: 'background-color .4s ease 0s',
						minWidth: '150px!important',
						borderRadius: '22px!important',
					},
					'.form-radio:checked, .form-radio:focus, .form-checkbox:checked': {
						background: 'var(--color-secondary)',
					},
					select: {
						borderRadius: '32px!important',
					},
					'.card': {
						background: 'rgba(122,125,76,.1)',
					},
				},
				colors: {
					primary: '#79856F',
					secondary: '#bb8644',
					textPrimary: '#ececec',
				},
				webFonts: [
					'https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600&display=swap',
				],
			},

		},
		image: combined,

	},
	Cyber: { // Blink Cyber Skinned
		name: 'Cyber',
		id: 'CYBER',
		theme: {
			baseTheme: 'base',
			palette: 'base',
			overrides: {
				styles: {
					'#boost-pet-dev #offer-content-container-BEFORE-0': {
						display: 'flex',
						alignItems: 'center',
					},
					'#boost-pet-dev #offer-content-container-BEFORE-0 p': {
						marginRight: '3rem',
						maxWidth: '50%',
					},
					'#offer-container': {
						maxWidth: '1060px',
						margin: '0 auto',
					},
					'.progress-bar-container, .progress-bar-label': {
						display: 'none',
					},
					'.single-form-view-container': {
						backgroundColor: 'white',
						minHeight: '150px',
						padding: '2rem',
						border: '2px solid #999',
						margin: '1rem',
					},
					'.input-text': {
						border: 'dotted 2px var(--color-primary)',
						borderRadius: '1px',
						height: '60px',
					},
					'label[for="policy-plan"], label[for="policy-paymentPlan"]': {
						display: 'none',
					},
					'.input-select': {
						border: 'dotted 2px var(--color-primary)',
						borderRadius: '0',
					},
					'.input-label': {
						textAlign: 'left',
						opacity: '1',
						'&:focus': {
							opacity: '1',
							fontSize: '0px solid var(--color-primary)',
						},
					},
					th: {
						background: 'var(--color-primary)',
						color: '#FFF',
					},
					'.input-error': {
						textAlign: 'left',
					},
					'.radio-button-wrapper': {
						display: 'flex',
						justifyContent: 'space-evenly',
					},
					'.radio-button-label': {
						display: 'inline',
					},
					'.radio-button-option-wrapper': {
						display: 'flow-root',
						minWidth: '30%',
						marginRight: '0px!important',
					},
					'td, th': {
						padding: '.65rem',
					},
					'#offer-view-container hr': {
						marginTop: '1rem',
						marginBottom: '1rem',
						border: 'dotted 2px var(--color-primary)',
					},
					'#offer-view-container p': {
						marginBottom: '1rem',
					},
					'#policy-planType-field-container .input-label': {
						textAlign: 'center',
					},
					'label[for=policy-planPayment]': {
						'text-align': 'center',
						'font-weight': 'bold',
						margin: '1.5rem',
					},
					'#quote-view-container hr': {
						marginTop: '1rem',
						marginBottom: '1rem',
					},
					'#quote-view-container #quote-content-container-BEFORE-0': {
						display: 'flex',
						justifyContent: 'end',
						marginTop: '-30px',
						'@media (max-width: 992px)': {
							justifyContent: 'flex-end',
							marginTop: '-36px',
						},
					},
					'#quote-view-container #quote-content-container-BEFORE-0 h2': {
						fontSize: '1.75em',
					},
					'label[for=policy-plan]': {
						display: 'none !important',
					},
					'#policy-planType-field-container div fieldset label[for="policy-planType-me"]:before': {
						position: 'relative',
						display: 'block',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						content: "''",
						width: '100px',
						height: '100px',
						backgroundImage: 'url("http://simpleicon.com/wp-content/uploads/user1.svg")',
					},
					'#policy-planType-field-container div fieldset label[for="policy-planType-shared"]:before': {
						position: 'relative',
						display: 'block',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						content: "''",
						width: '100px',
						height: '100px',
						backgroundImage: 'url("http://simpleicon.com/wp-content/uploads/add-user.svg")',
					},
					'label[for=policy-planPayment-Monthly],label[for=policy-planPayment-Annual], #policy-planType-field-container label, #policy-plan-field-container label, #policy-paymentPlan-field-container label': {
						transition: '200ms',
						fontSize: '16px !important',
						border: '3px dotted var(--color-primary)',
						padding: '20px',
						display: 'block',
					},
					'#policy-planPayment-field-container input[type=radio], #policy-planType-field-container input[type=radio], #policy-plan-field-container input[type=radio], #policy-paymentPlan-field-container input[type=radio]': {
						border: '0',
						clip: 'rect(0, 0, 0, 0)',
						height: '1px',
						overflow: 'hidden',
						padding: '0',
						position: 'absolute !important',
						whiteSpace: 'nowrap',
						width: '1px',
					},
					'label[for=policy-planPayment-Monthly], label[for=policy-planPayment-Annual], #policy-planType-field-container input[type=radio] + label, #policy-plan-field-container input[type=radio] + label, #policy-paymentPlan-field-container input[type=radio] + label': {
						background: '#fff',
						color: 'var(--color-primary)',
						fill: '#ddd',
						textAlign: 'center',
						fontSize: '20px !important',
						textTransform: 'uppercase',
						fontWeight: 'bold',
						display: 'block',
					},
					'#policy-planPayment-field-container input[type=radio]:hover + label, #policy-planType-field-container input[type=radio]:hover + label, #policy-plan-field-container input[type=radio]:hover + label, #policy-paymentPlan-field-container input[type=radio]:hover + label': {
						background: 'rgba(254, 31, 165, 0.15)',
						color: 'var(--color-primary)',
					},
					'#policy-planPayment-field-container input[type=radio]:focus + label, #policy-planType-field-container input[type=radio]:focus + label, #policy-plan-field-container input[type=radio]:focus + label, #policy-paymentPlan-field-container input[type=radio]:focus + label': {
						background: '#999',
						fill: '#000',
						color: '#000',
					},
					'#policy-planPayment-field-container input[type=radio]:checked:hover + label, #policy-planType-field-container input[type=radio]:checked:hover + label, #policy-plan-field-container input[type=radio]:checked:hover + label, #policy-paymentPlan-field-container input[type=radio]:checked:hover + label': {
						background: 'rgba(254, 31, 165, 0.15)',
						fill: '#000',
					},
					'#policy-planPayment-field-container input[type=radio]:checked:focus + label, #policy-planType-field-container input[type=radio]:checked:focus + label, #policy-plan-field-container input[type=radio]:checked:focus + label, #policy-paymentPlan-field-container input[type=radio]:checked:focus + label': {
						borderTop: '5px solid #ff0198',
						borderLeft: '3px solid var(--color-primary)',
						borderRight: '3px solid var(--color-primary)',
						borderBottom: '1px solid var(--color-primary)',
						fill: '#000',
						background: 'rgba(254, 31, 165, 0.15)',
						color: 'var(--color-primary)',
					},
					'#policy-planPayment-field-container input[type=radio]:checked + label, #policy-planType-field-container input[type=radio]:checked + label, #policy-plan-field-container input[type=radio]:checked + label': {
						borderTop: '5px solid #ff0198',
						borderLeft: '3px solid var(--color-primary)',
						borderRight: '3px solid var(--color-primary)',
						borderBottom: '1px solid var(--color-primary)',
						fill: '#000',
						background: 'rgba(254, 31, 165, 0.15)',
						color: 'var(--color-primary)',
						display: 'block',
					},
					'label[for="policy-planType"]': {
						border: 'none !important',
					},
					label: {
						fontSize: '16px !important',
					},
					'#customerInfo-view-container div': {
						display: 'flex',
						flexWrap: 'wrap',
						margin: '5px 0',
						justifyContent: 'space-between',
					},
					'#customer-middleInitial-field-container, #customer-firstName-field-container, #customer-lastName-field-container, #customer-address-line1-field-container, #customer-lastName-field-container, #customer-address-line2-field-container': {
						display: 'inline-block',
						width: '48%',
						marginRight: '1rem',
					},
					'#customer-address-city-field-container, #customer-address-state-field-container, #customer-phone-field-container': {
						display: 'inline-block',
						width: '48%',
						marginRight: '1rem',
					},
					body: {
						fontFamily: 'Lato, sans-serif',
						fontSize: '16px',
						'@media (min-width: 768px)': {
							fontSize: '16px',
						},
						margin: '0 auto',
					},
					h1: {
						fontFamily: 'Lato, sans-serif',
						fontWeight: '700',
						fontSize: '52px',
					},
					h2: {
						fontFamily: 'Lato, sans-serif',
						fontWeight: 'bold',
						fontSize: '35px',
						color: 'var(--color-primary)',
					},
					h3: {
						fontFamily: 'Lato, sans-serif',
						fontWeight: '400',
						fontSize: '20px',
					},
					'ul, ol': {
						listStylePosition: 'inside',
					},
					table: {
						maxWidth: '100%',
					},
					a: {
						color: 'var(--color-primary)',
					},
					p: {
						color: 'var(--color-primary)',
					},
					'.button-secondary': {
						border: '2px solid var(--color-primary)',
						textTransform: 'uppercase',
						padding: 0,
						color: 'var(--color-primary)',
						fontWeight: 'bold',
						fontSize: '14px',
						height: '40px',
						background: 'none',
						'&:hover': {
							backgroundColor: 'var(--color-primary)',
							color: '#fff',
							textDecoration: 'none',
						},
					},
					'.button-primary': {
						textTransform: 'uppercase',
						padding: 0,
						color: '#fff',
						fontWeight: 'bold',
						fontSize: '14px',
						height: '40px',
						background: 'var(--color-primary)',
						'&:hover': {
							backgroundColor: 'var(--color-secondary)',
							color: '#fff',
						},
					},
					button: {
						border: '2px solid var(--color-primary)',
						minWidth: '150px!important',
						borderRadius: '1px!important',
					},
				},
				colors: {
					primary: 'rgb(21, 15, 150)',
					secondary: 'rgb(11, 8, 94)',
					accent: '#ff0198',
					textPrimary: 'rgb(96,94,94)',
				},
				webFonts: [
					'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300&display=swap',
				],
			},
		},
		image: blink,
	},
	Pet: { // Iams Pet Skinned
		name: 'Pet',
		id: 'PET',
		theme: {
			baseTheme: 'base',
			palette: 'base',
			overrides: {
				styles: {
					'#blink-cyber #offer-view-container h2': {
						color: 'var(--color-secondary)',
					},
					'.progress-bar-label': {
						marginTop: '-2.2rem',
						display: 'none',
					},
					'.progress-bar-container': {
						marginBottom: '2.2rem',
					},
					'#offer-view-container hr': {
						marginTop: '1em',
						marginBottom: '1em',
					},
					'.view-container': {
						marginTop: '0',
						minHeight: '350px',
					},
					'.card': {
						paddingTop: '1rem',
						'@media (min-width: 992px)': {
							flexDirection: 'row',
							paddingTop: '1rem',
						},
					},
					'.view-title': {
						fontSize: '1.5rem',
						lineHeight: '2rem',
					},
					'.view-subtitle': {
						fontSize: '1.125rem',
						lineHeight: '1.75rem',
					},
					'label[for=policy-planType-PLATINUM],label[for=policy-planType-GOLD],label[for=policy-planType-SILVER], label[for=policy-insuredRisks-petType-cat], label[for=policy-insuredRisks-petType-dog], label[for=policy-insuredRisks-petGender-male], label[for=policy-insuredRisks-petGender-female], label[for=policy-insuredRisks-isInGoodHealth-true], label[for=policy-insuredRisks-isInGoodHealth-false]': {
						fontSize: '24px !important',
						fontWeight: 'bold',
					},
					label: {
						fontSize: '16px !important',
					},
					'label[for=policy-planPayment]': {
						'text-align': 'center',
					},
					'label[for=policy-plan]': {
						display: 'none',
					},
					'#offer-container': {
						maxWidth: '1060px',
						margin: '0 auto',
					},
					'#offer-container .card': {
						backgroundColor: '#eceaea',
					},
					'.radio-button-label': {
						marginRight: '1em',
					},
					'#offer-view-container h2:first-of-type': {
						color: 'var(--color-primary)',
						fontSize: '2.5rem',
					},
					'#offer-view-container #offer-content-container-BEFORE-0 h2': {
						color: 'var(--background-primary)',
						fontSize: '2.5rem',
					},
					'#offer-content-container-BEFORE-0': {
						display: 'flex',
						alignItems: 'center',
					},
					'#offer-content-container-BEFORE-0 p': {
						marginRight: '3rem',
						maxWidth: '50%',
					},
					'#offer-content-container-BEFORE-1': {
						color: 'var(--background-primary)',
					},
					'#offer-view-container div': {
						backgroundColor: 'var(--color-primary)',
						padding: '2em',
						'@media (max-width: 992px)': {
							width: '100%',
							paddingTop: '1.5em',
							paddingBottom: '25px',
						},
					},
					'#vigilance-occupational-accident #quote-content-container-AFTER-0': {
						flexDirection: 'column',
						margin: 'auto',
					},
					'#vigilance-occupational-accident #quote-content-container-AFTER-3': {
						margin: 'auto',
						marginTop: '2em',
						justifyContent: 'flex-start',
						maxWidth: '700px',
					},
					'#vigilance-occupational-accident #offer-view-container div': {
						backgroundColor: 'transparent',
						padding: '.5em',
					},
					'#blink-cyber #offer-view-container div': {
						backgroundColor: 'var(--color-primary)',
						padding: '1em',
						'@media (max-width: 992px)': {},
					},
					'#offer-view-container #offer-view-subtitle': {
						'@media (max-width: 992px)': {},
					},
					'#offer-view-container div p': {
						fontSize: '1.15em',
						fontWeight: 'bold',
					},
					table: {
						backgroundColor: '#fff',
					},
					'label[for=policy-insuredRisks-petDobMonth], label[for=policy-insuredRisks-petDobYear], label[for=policy-insuredRisks-petType], label[for=policy-insuredRisks-petGender], label[for=policy-insuredRisks-isInGoodHealth], label[for=policy-policyAttributes-policyState]': {
						display: 'none',
					},
					'#policy-insuredRisks-petType-field-container, #policy-insuredRisks-petGender-field-container, #policy-insuredRisks-isInGoodHealth-field-container': {
						display: 'flex',
						justifyContent: 'center',
					},
					'#policy-insuredRisks-petGender-field-container div:first-of-type, #policy-insuredRisks-isInGoodHealth-field-container div:first-of-type': {
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
					},
					'#policy-insuredRisks-petType-field-container, #policy-policyAttributes-policyState-field-container': {
						flexWrap: 'wrap',
					},
					'.view-container#insuredRisksName-view-container, .view-container#insuredRisksType-view-container, .view-container#insuredRisksDOB-view-container, .view-container#insuredRisksGender-view-container, .view-container#insuredRisksHealth-view-container, .view-container#insuredRisksState-view-container,': {
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '300px',
						height: '40vh',
						maxHeight: '550px',
					},
					'#nav-button-container': {
						justifyContent: 'center',
					},
					'#nav-button-container button': {
						margin: '2em',
					},
					// '#insuredRisksType-view-container .input-select,#insuredRisksName-view-container .input-select, .input-text': {
					// 	borderRadius: '40px',
					// 	padding: '0px',
					// 	paddingLeft: '16px',
					// },
					'.input-select, .input-text': {
						borderRadius: '40px',
					},
					'.single-form-view-container': {
						borderBottomColor: 'rgba(82, 67, 65, .5)',
					},
					'#quote-content-container-AFTER-0': {
						'@media (max-width: 992px)': {
							marginTop: '50px',
						},
					},
					'#quote-view-wrapper .input-error': {
						position: 'relative',
						top: '-1055px',
						textAlign: 'center',
						'@media (max-width: 992px)': {
							top: '-1250px',
						},
					},
					'#quote-view-container div:first-of-type, #quote-view-container .content-container': {
						display: 'flex',
						justifyContent: 'center',
						flexWrap: 'wrap',
					},
					'#quote-view-container #quote-content-container-AFTER-1 td, #quote-view-container #quote-content-container-AFTER-1 th': {
						'@media (max-width: 992px)': {
							fontSize: '13px',
						},
					},
					'#quote-view-container #quote-content-container-BEFORE-0': {
						display: 'flex',
						justifyContent: 'end',
						marginTop: '-30px',
						'@media (max-width: 992px)': {
							justifyContent: 'center',
							marginTop: '36px',
						},
					},
					'#quote-view-container #quote-content-container-BEFORE-0 h2': {
						fontSize: '1.75em',
					},
					'#quote-view-container label[for=policy-planType]': {
						display: 'none',
					},
					'#quote-view-container table': {
						marginTop: '2em',
					},
					'#vigilance-occupational-accident #quote-view-container table': {
						margin: 'auto',
						marginTop: '2em',
					},
					'#vigilance-occupational-accident #quote-view-container #quote-content-container-AFTER-2 p, #vigilance-occupational-accident #quote-view-container #quote-content-container-AFTER-1 p, #vigilance-occupational-accident #quote-view-container #quote-content-container-AFTER-0 p': {
						display: 'none',
					},
					'#quote-view-container td img': {
						margin: 'auto',
					},
					'#quote-view-container view-title': {
						marginBottom: '.75em',
					},
					'#checkout-content-container-AFTER-0': {
						marginTop: '0',
						marginBottom: '2em',
					},
					'#quote-view-container #quote-view-title': {
						color: '#000',
					},
					'#insuredRisksType-view-container .radio-button-wrapper': {
						display: 'flex',
					},
					'#insuredRisksType-view-container .field-container div fieldset label[for=policy-insuredRisks-petType-cat]:before': {
						position: 'relative',
						display: 'block',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						content: "''",
						width: '100px',
						height: '100px',
						left: '-5px',
						backgroundImage: 'url("https://s3.amazonaws.com/staging.embed.buddy.insure/cat.svg")',
					},
					'#insuredRisksType-view-container .field-container div fieldset label[for=policy-insuredRisks-petType-dog]:before': {
						position: 'relative',
						display: 'block',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						content: "''",
						width: '100px',
						height: '100px',
						left: '0',
						backgroundImage: 'url("https://s3.amazonaws.com/staging.embed.buddy.insure/dog.svg")',
					},
					'#insuredRisksType-view-container div:first-child label, #insuredRisksType-view-container div:nth-child(2) label': {
						transition: '200ms',
						fontSize: '16px !important',
						borderRadius: '50px',
						padding: '20px',
					},
					'#insuredRisksType-view-container input[type=radio]': {
						border: '0',
						clip: 'rect(0, 0, 0, 0)',
						height: '1px',
						overflow: 'hidden',
						padding: '0',
						position: 'absolute !important',
						whiteSpace: 'nowrap',
						width: '1px',
					},
					'#insuredRisksType-view-container input[type=radio] + label': {
						background: '#fff',
						color: 'var(--color-primary)',
						fill: '#ddd',
						textAlign: 'center',
						fontSize: '24px !important',
						fontWeight: 'bold',
					},
					'#insuredRisksType-view-container input[type=radio]:hover + label': {
						background: '#999',
						fill: '#000',
						color: '#000',
					},
					'#insuredRisksType-view-container input[type=radio]:focus + label': {
						background: '#999',
						fill: '#000',
						color: '#000',
					},
					'#insuredRisksType-view-container input[type=radio]:checked:hover + label': {
						background: 'var(--color-primary)',
						fill: '#000',
						color: '#FFF',
					},
					'#insuredRisksType-view-container input[type=radio]:checked:focus + label': {
						background: 'var(--color-primary)',
						fill: '#000',
						color: '#FFF',
					},
					'#insuredRisksType-view-container input[type=radio]:checked + label': {
						background: 'var(--color-primary)',
						fill: '#000',
						color: '#FFF',
					},
					'#checkout-wrapper div .mb-4': {
						fontSize: '1.35em',
					},
					'#insuredRisksDOB-view-container div, #customerName-view-container div': {
						display: 'flex',
						flexWrap: 'wrap',
					},
					'#insuredRisksDOB-view-container div .field-container, #customerName-view-container div .field-container': {
						display: 'block',
						marginRight: '10px',
						width: '45%',
						'@media (max-width: 992px)': {
							width: '100%',
						},
					},
					'#insuredRisksDOB-view-container div .field-container select, #customerName-view-container div .field-container input': {
						height: 'auto',
					},
					'#policy-insuredRisks-catBreed, #policy-insuredRisks-dogBreed, #policy-insuredRisks-catBreed div:first-of-type, #policy-insuredRisks-dogBreed div:first-of-type': {
						borderRadius: '30px',
					},
					'#boost-pet-dev #checkout-view-container table': {
						marginTop: '2em',
						marginBottom: '2em',
					},
					'#boost-pet-dev #checkout-content-container-BEFORE-1': {
						display: 'flex',
						justifyContent: 'center',
					},
					'#boost-pet-dev #checkout-content-container-BEFORE-0': {
						display: 'flex',
						justifyContent: 'end',
						marginTop: '-30px',
						'@media (max-width: 992px)': {
							justifyContent: 'center',
							marginTop: '36px',
						},
					},
					'#boost-pet-dev #checkout-content-container-BEFORE-0 h2': {
						fontSize: '1.75em',
					},
					'#plans-view-container td, #quote-view-container td, #checkout-view-container td': {
						padding: '.5rem !important',
						paddingLeft: '.75rem !important',
						'@media (max-width: 992px)': {
							padding: '.35rem !important',
						},
					},
					'#quote-price-label': {
						display: 'none',
					},
					'#vigilance-occupational-accident #quote-price-label': {
						display: 'block',
						textAlign: 'center',
						fontSize: '1.5em',
					},
					body: {
						fontFamily: 'Varela Round, sans-serif',
						fontSize: '16px',
						'@media (min-width: 768px)': {
							fontSize: '16px',
						},
						margin: '0 auto',
					},
					h2: {
						fontFamily: 'Varela Round, sans-serif',
						fontWeight: 'bold',
						fontSize: '35px',
						color: 'var(--color-primary)',
						lineHeight: '1.15',
						'@media (max-width: 768px)': {
							textAlign: 'center',
						},
					},
					h3: {
						fontFamily: 'Varela Round, sans-serif',
						fontWeight: 'bold',
						fontSize: '20px',
						color: 'var(--color-text-primary)',
					},
					a: {
						color: 'var(--color-primary)',
					},
					th: {
						background: 'var(--color-primary)',
						color: '#fff',
					},
					'.button-secondary': {
						color: 'var(--color-primary)',
						'&:focus': {
							outline: '2px solid var(--color-primary)',
						},
					},
					'.button-primary': {
						borderRadius: '20px',
						transition: 'border-color .4s ease 0s,background-color .4s ease 0s',
						textTransform: 'uppercase',
						padding: 0,
						background: 'var(--color-primary)',
						border: 'solid 2px var(--color-secondary)',
						color: '#fff',
						fontWeight: 'bold',
						fontFamily: 'Varela Round, sans-serif',
						fontSize: '13px',
						height: '40px',
						'&:hover': {
							backgroundColor: 'var(--color-secondary)',
							color: '#fff',
						},
						'&:focus': {
							outline: '2px solid var(--color-secondary)',
						},
					},
					'input[type=radio], input[type=checkbox]': {
						color: 'var(--color-primary)',
						'&:focus': {
							outline: '2px solid var(--color-primary)',
						},
					},
					'input::placeholder': {
						color: 'var(--color-text-primary)',
						opacity: 0.4,
					},
					strong: {
						fontFamily: 'Varela Round',
						fontStyle: 'normal',
						fontVariant: 'normal',
						fontWeight: 700,
					},
				},
				colors: {
					primary: '#ed6e2f',
					secondary: '#BEAFA1',
					textPrimary: 'rgb(96,94,94)',
				},
				webFonts: [
					'https://fonts.googleapis.com/css2?family=Varela+Round:wght@400;700&display=swap',
				],
			},
		},
	},
	Needlestick: { // Vigilance Needlestick Skinned
		name: 'Needlestick',
		id: 'NEEDLESTICK',
		theme: {
			baseTheme: 'base',
			palette: 'base',
			overrides: {
				styles: {
					'#boost-pet-dev #offer-content-container-BEFORE-0': {
						display: 'flex',
						alignItems: 'center',
					},
					'#boost-pet-dev #offer-content-container-BEFORE-0 p': {
						marginRight: '3rem',
						maxWidth: '50%',
					},
					'#offer-view-container hr': {
						marginTop: '1em',
						marginBottom: '1em',
					},
					'.progress-bar-label': {
						marginTop: '-2.2rem',
					},
					'.view-container': {
						marginTop: '4rem',
					},
					'.card': {
						paddingTop: '1rem',
						'@media (min-width: 992px)': {
							flexDirection: 'row',
							paddingTop: '1rem',
						},
					},
					'.view-title': {
						fontSize: '1.5rem',
						lineHeight: '2rem',
					},
					'.view-subtitle': {
						fontSize: '1.125rem',
						lineHeight: '1.75rem',
					},
					body: {
						fontFamily: 'open sans, sans-serif',
						fontSize: '16px',
						'@media (min-width: 768px)': {
							fontSize: '16px',
						},
					},
					h2: {
						fontFamily: 'open sans, sans-serif',
						fontWeight: 'bold',
						fontSize: '35px',
						color: 'rgb(47,46,46)',
					},
					h3: {
						fontFamily: 'open sans, sans-serif',
						fontWeight: 'bold',
						fontSize: '20px',
						color: 'rgb(47,46,46)',
					},
					a: {
						color: 'var(--color-primary)',
					},
					'ul, ol': {
						listStylePosition: 'inside',
					},
					th: {
						background: 'var(--color-primary)',
						color: '#fff',
					},
					'.button-secondary': {
						color: 'var(--color-primary)',
						'&:focus': {
							outline: '2px solid var(--color-primary)',
						},
					},
					'.button-primary': {
						borderRadius: '20px',
						transition: 'border-color .4s ease 0s,background-color .4s ease 0s',
						textTransform: 'uppercase',
						padding: 0,
						background: '#fff',
						border: 'solid 2px var(--color-secondary)',
						color: 'var(--color-secondary)',
						fontWeight: 'normal',
						fontFamily: 'open sans,sans-serif',
						fontSize: '13px',
						height: '40px',
						'&:hover': {
							backgroundColor: 'var(--color-secondary)',
							color: '#fff',
						},
						'&:focus': {
							outline: '2px solid var(--color-secondary)',
						},
					},
					'input[type=radio]': {
						color: 'var(--color-primary)',
						'&:focus': {
							outline: '2px solid var(--color-primary)',
						},
					},
					'input::placeholder': {
						color: 'var(--color-text-primary)',
						opacity: 0.4,
					},
				},
				colors: {
					primary: 'rgb(25,125,116)',
					secondary: 'rgb(229,32,25)',
					textPrimary: 'rgb(96,94,94)',
				},
				webFonts: [
					'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap',
				],
			},
		},
	},
};
export default THEMES;
