import React from 'react';

function BuddyB() {
	return (
		<svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="34" cy="34" r="34" fill="#DC5648" />
			<path fillRule="evenodd" clipRule="evenodd" d="M29.1987 50.6968C32.451 50.6899 35.7064 50.6829 38.9193 50.3182C43.8616 49.7572 47.6012 46.4816 47.0347 40.4755C46.7943 37.9269 45.5066 35.9896 43.1506 34.8524C42.2727 34.4287 42.2571 34.3921 43.0149 33.776C46.0071 31.3428 46.7731 26.7382 44.6587 23.5242C42.9616 20.9442 40.2898 19.9746 37.4204 19.7928C34.4409 19.6041 31.4473 19.6183 28.4561 19.6325C27.7745 19.6358 27.093 19.639 26.4119 19.6399C24.502 19.642 23.8692 20.3426 23.8667 22.2423C23.8623 25.3021 23.8631 28.3619 23.864 31.4218C23.8643 32.6568 23.8647 33.8917 23.8647 35.1267H23.8681C23.8681 36.368 23.8674 37.6093 23.8668 38.8506C23.8651 42.0463 23.8633 45.242 23.873 48.4377C23.8774 49.8529 24.6174 50.7357 25.9712 50.7167C27.0456 50.7014 28.122 50.6991 29.1987 50.6968ZM36.9416 39.8851H31.8802C31.3741 39.8851 30.9723 40.2798 31.0037 40.7495C31.0974 42.1506 31.6432 44.7626 34.4109 44.7626C37.1788 44.7626 37.7246 42.1506 37.8182 40.7495C37.8495 40.2798 37.4477 39.8851 36.9416 39.8851Z" fill="white" />
		</svg>
	);
}

export default BuddyB;
