import React, { useContext } from 'react';
import {
	Card, Input, VisualPicker, VisualPickerOption, Button, Modal, Application, VisualPickerOptionFooter,
} from 'react-rainbow-components';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/mode-javascript';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import vs2015 from 'react-syntax-highlighter/dist/esm/styles/hljs/vs2015';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCode,
	faSquare,
	faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { CopyButton } from '../components/CopyToClipboard';
import OfferElementCard from '../components/OfferElementCard';
import SectionHeader from '../components/SectionHeaders';
import { AppContext } from '../contexts/AppContext';
import Header from '../components/Header';

import THEMES from '../models/themes';

function BuildTheme() {
	const {
		updateTheme,
		theme,
		modalIsOpen,
		updateModalIsOpen,
		data,
		updateData,
		selectedProducts,
		themeModalIsOpen,
		updateThemeModalIsOpen,
	} = useContext(AppContext);

	const handleThemeSelection = (value) => {
		updateTheme(value);
	};

	const handleToggleModal = () => updateModalIsOpen(modalIsOpen);

	const handleCloseModal = () => updateThemeModalIsOpen(themeModalIsOpen);

	const handleFormUpdate = (_data) => {
		const { target: { name, value } } = _data;
		updateData({ [name]: value });
	};

	const selectedProductsStringified = selectedProducts.map((item) => `'${item}'`).join(', ');

	const { name } = theme;

	const codeString1 = `
	<div id="buddy_offer"></div>
	`;

	const codeString2 = `
	<script src="https://js.buddy.insure/v2/demo/itc/index.js"></script>
	<script type="application/javascript">

	var options = {
		partnerID: 'p-buddytest',
		selectedProducts: [${selectedProductsStringified}],
		theme: ${JSON.stringify(theme.theme)},
		data: ${JSON.stringify(data)},
		overflow: 'hidden',
		onUserEvent: () => {},

		// values for stage are "STAGING" or "PRODUCTION"
		stage: 'STAGING'
	};

	window.Buddy.createOffer(options);
</script>`;

	const codeStrings = {
		scriptTag: {
			code: codeString1,
			copy: 'Place the div element below where you want the bundle to appear in your website.',
		},
		div: {
			code: codeString2,
			copy: 'Paste the code snippet below directly above the closing </body> tag in your html document.',
		},
	};

	const themeObject = `{
	name: 'PickThemeName',
	id: 'PickThemeID',
	theme: {
		baseTheme: 'base',
		palette: 'base',
		overrides: {
			colors: {
				primary: '#dc5648',
				secondary: '#05445b',
				textPrimary: '#fda057',
			},
		},
	},
},
	`;

	const cardBackground = {
		rainbow: {
			palette: {
				mainBackground: '#f8f8f8',
			},
		},
	};

	const buddyRedTheme = {
		rainbow: {
			palette: {
				brand: '#dc5648',
				mainBackground: '#FFFFFF',
			},
		},
	};

	const buddyBlueTheme = {
		rainbow: {
			palette: {
				brand: '#05445b',
			},
		},
	};

	const divStyle = {
		backgroundColor: '#f8f8f8',
	};

	return (
		<Application
			theme={buddyRedTheme}
			style={{ fontFamily: 'Karla' }}
		>
			<Header />

			<div className="lg:mx-auto max-w-screen-2xl">
				<SectionHeader
					header="Integration Configuration"
					copy="Select the theme and assign your partners."
				/>
				<Card className="flex flex-wrap sm:flex-nowrap py-6 px-4 sm:px-10 mx-4 sm:mx-10 sm:mb-10">
					<div className="w-full sm:w-1/4 sm:mr-6">
						<h2 className="py-2 px-2">Select Theme</h2>
						<Card
							className="py-2 px-2 my-2 mx-2 flex flex-col items-center"
							style={divStyle}
						>
							<VisualPicker
								id="theme-selector"
								value={name}
								onChange={handleThemeSelection}
								size="small"
							>
								{Object.keys(THEMES).map((item) => (
									<VisualPickerOption
										name={THEMES[item].name}
										key={THEMES[item].name}
										className="py-2"
										footer={
											<VisualPickerOptionFooter description={THEMES[item].name} style={{ fontFamily: 'Karla', fontSize: 7 }} />
										}
									>
										<div>
											<FontAwesomeIcon icon={faSquare} className="fa-xl mx-1 stroke-black" color={THEMES[item].theme.overrides.colors.primary} />
											<FontAwesomeIcon icon={faSquare} className="fa-xl mx-1 stroke-black" color={THEMES[item].theme.overrides.colors.secondary} />
											<FontAwesomeIcon icon={faSquare} className="fa-xl mx-1 stroke-black" color={THEMES[item].theme.overrides.colors.textPrimary} />
										</div>
									</VisualPickerOption>
								))}
							</VisualPicker>
							<Button variant="border-filled" className="py-2 px-2 mx-2 my-2" onClick={handleCloseModal}>
								Add Theme
								<FontAwesomeIcon icon={faPlus} className="ml-2" />
							</Button>
						</Card>

						<h2 className="py-2 px-2 mb-2 mt-6">Add Channel & Producer</h2>

						<Card className="py-2 px-2 mt-2 mb-6 mx-2" style={divStyle}>

							<Application
								theme={buddyRedTheme}
								style={{ fontFamily: 'Karla' }}
							>
								<Input
									id="channel"
									name="channel"
									label="Channel"
									placeholder="Channel"
									className="py-2 px-2"
									labelAlignment="left"
									onBlur={handleFormUpdate}
								/>

								<Input
									id="producer"
									name="producer"
									label="Producer"
									placeholder="Producer"
									className="py-2 px-2"
									labelAlignment="left"
									onBlur={handleFormUpdate}
								/>
							</Application>

						</Card>

					</div>
					<div className="w-full sm:w-4/5">
						<Application
							theme={buddyBlueTheme}
							style={{ fontFamily: 'Karla' }}
						>

							<Button
								variant="brand"
								className="float-right py-2 px-2 my-2 mx-2"
								onClick={handleToggleModal}
							>
								Get Code
								<FontAwesomeIcon icon={faCode} className="ml-2" />
							</Button>
						</Application>

						<Application
							theme={cardBackground}
							style={{ fontFamily: 'Karla' }}
						>
							<h2 className="py-2 px-2">Preview</h2>
							<OfferElementCard />
						</Application>
					</div>

				</Card>

				<Application
					theme={buddyBlueTheme}
					style={{ fontFamily: 'Karla' }}
				>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={handleToggleModal}
						size="large"
					>
						{Object.keys(codeStrings).map((string) => (
							<>
								<p className="text-lg mt-2 mx-10 mb-2" style={{ fontFamily: 'Karla' }}>{codeStrings[string].copy}</p>
								<SyntaxHighlighter
									style={vs2015}
									className="text-xs mx-10 rounded-lg"
								>
									{codeStrings[string].code}
								</SyntaxHighlighter>
								<div className="flex justify-end px-3 my-4 mx-10 ">
									<CopyButton whatToCopy={codeStrings[string].code} />
								</div>
							</>
						))}

					</Modal>

					<Modal
						isOpen={themeModalIsOpen}
						onRequestClose={handleCloseModal}
					>
						<p className="text-lg my-4" style={{ fontFamily: 'Karla' }}>Create Your Own Theme Below</p>

						<AceEditor
							mode="javascript"
							theme="twilight"
							className="w-full"
							width="100%"
							height="300px"
							name="style"
							wrapEnabled
							editorProps={{ $blockScrolling: true }}
							defaultValue={themeObject}
							style={{ width: '100%' }}
						/>
						<Button
							variant="brand"
							className="float-right mt-4"
							onClick={handleCloseModal}
						>
							Save Theme
						</Button>
					</Modal>
				</Application>
			</div>
		</Application>

	);
}

export default BuildTheme;
